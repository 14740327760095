import * as React from "react";
import { graphql, Link } from 'gatsby';
import MainLayout from '../components/mainLayout';
import SEO from '../components/seo';
import styledComponents from 'styled-components';

const Links = styledComponents.ul`
list-style: none;

font-size: 1.25rem;

margin: 0;
padding: 0;

li {
  margin-bottom: 0.5rem;
}
`;

// markup
const IndexPage = ({ data }) => {
  return (
    <main>
      <MainLayout>
        <SEO description='double text with love behandelt Themen, mit denen viele junge Menschen konfrontiert werden. Digitale Kommunikation und die Kommodifizierung von romantischen Erlebnissen gehören zum Alltag unserer Figuren. Tauche zusammen mit ihnen ein in eine Welt, in welcher sich vor Reizüberflutung nicht zu retten ist - wodurch qualitative Interaktionen umso wichtiger werden.' />
        <h1>double text with love</h1>

        <div className="text-content">
          <p>double text with love behandelt Themen, mit denen viele junge Menschen konfrontiert werden. Digitale Kommunikation und die Kommodifizierung von romantischen Erlebnissen gehören zum Alltag unserer Figuren. Tauche zusammen mit ihnen ein in eine Welt, in welcher sich vor Reizüberflutung nicht zu retten ist &mdash; wodurch qualitative und emotional signifikante Interaktionen umso wichtiger werden.</p>
        </div>

        <Links>
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <li key={node.id}>
              <Link to={`/${node.frontmatter.slug}`}>{node.frontmatter.title}</Link>
            </li>
          ))}
        </Links>
      </MainLayout>
    </main>
  )
}

export default IndexPage;


export const query = graphql`
query {
  allMarkdownRemark(sort: { order: ASC, fields: [frontmatter___order] }) {
    edges {
      node {
        id
        frontmatter {
          title
          date
          slug
        }
      }
    }
  }
}
`
